'use client';

import { twMerge } from 'tailwind-merge';

import type { AgencyMenu } from '@homeflow/next/models';

import { SerializableBranch } from '@/serializers/branch';
import { SerializableAgency } from '@/serializers/agency';

import LinkButton from '@rigel/components/buttons/link-button.component';
import Container from '@rigel/components/container.component';

import MobileNavItem from './mobile-nav-item.component';
import MobileNavBranchItem from './mobile-nav-branch-item.component';
import SocialIcons from '../social-icons.component';
import { ButtonSize } from '@homeflow/next/state';

export default function MobileNavDropDown({
  shown,
  toggleNav,
  menu,
  branches,
  agency,
}: {
  shown: boolean;
  toggleNav: Function;
  menu: AgencyMenu;
  branches: SerializableBranch[];
  agency: SerializableAgency;
}) {
  if (!menu) return null;

  const { menu_items: menuItems } = menu;
  return (
    <>
      {shown && (
        <div
          className="fixed inset-0 bg-navigation-primary-background -z-10 opacity-50 print:hidden"
          onClick={() => toggleNav()}
        ></div>
      )}
      <div
        className={twMerge(
          'rigel-mobile-nav-dropdown grid transition-all duration-500 w-full absolute bg-navigation-primary-background print:hidden',
          shown && 'grid-rows-[1fr] ease-in',
          !shown && 'grid-rows-[0fr] ease-out'
        )}
      >
        <div className="overflow-hidden w-full">
          <div className="py-4">
            <Container>
              {menuItems?.map((item) => <MobileNavItem key={item.name} item={item} />)}

              {branches.map((branch) => (
                <MobileNavBranchItem key={branch.name} branch={branch} />
              ))}

              <LinkButton
                href="/instant-valuation"
                size={ButtonSize.Small}
                className="my-10 inline-block"
              >
                Book a free valuation
              </LinkButton>

              <div className="flex gap-3 text-primary">
                <SocialIcons agency={agency} iconClassName="h-7 w-7 stroke-[1.5px]" />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
