'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { LuSearch } from 'react-icons/lu';

import type { AgencyMenu } from '@homeflow/next/models';
import { ButtonSize, ButtonVariant } from '@homeflow/next/state';

import { SerializableAgency } from '@/serializers/agency';
import { SerializableBranch } from '@/serializers/branch';
import Container from '@rigel/components/container.component';

import LinkButton from '../../buttons/link-button.component';
import MobileNavDropDown from './mobile-nav-drop-down.component';
import Hamburger from './hamburger.component';

interface Props {
  menu: AgencyMenu;
  agency: SerializableAgency;
  branches: SerializableBranch[];
}

export default function MobileNav({ menu, agency, branches }: Props) {
  const [menuShown, setMenuShown] = useState(false);

  const pathname = usePathname();

  useEffect(() => {
    setMenuShown(false);
  }, [pathname]);

  const toggleNav = (): void => {
    setMenuShown((prev) => !prev);
  };

  return (
    <nav className="rigel-mobile-nav py-4 w-full z-50 @2xl:hidden bg-site-header-background">
      <Container>
        <div className="flex items-center justify-between">
          {agency.logo && (
            <Link prefetch={false} href="/">
              <Image
                src={agency.logo}
                alt="Logo"
                width={183}
                height={48}
                className="w-auto h-12"
                priority
              />
            </Link>
          )}
          <div className="flex gap-4 items-center">
            <LinkButton
              variant={ButtonVariant.PrimaryBorder}
              size={ButtonSize.Large}
              href="/properties/sales"
              className="hidden @md:flex text-sm items-center gap-2"
            >
              <LuSearch />
              <span>Search Properties</span>
            </LinkButton>
            <LinkButton
              variant={ButtonVariant.Primary}
              size={ButtonSize.Large}
              href="/instant-valuation"
              className="hidden @md:block text-sm"
            >
              <span>Book a free valuation</span>
            </LinkButton>
            <button
              className="flex items-center ease-in-out duration-200 active:scale-75"
              type="button"
              onClick={() => toggleNav()}
              title={`${menuShown ? 'Hide' : 'Show'} menu`}
            >
              <Hamburger shown={menuShown} />
            </button>
          </div>
        </div>
      </Container>
      <MobileNavDropDown
        shown={menuShown}
        agency={agency}
        branches={branches}
        toggleNav={toggleNav}
        menu={menu}
      />
    </nav>
  );
}
