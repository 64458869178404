import { ButtonVariant, ButtonSize } from '@homeflow/next/state';
import { ButtonProps } from '@homeflow/next/theme/components/buttons/button-props';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const DEFAULT_VARIANT = ButtonVariant.Primary;
const DEFAULT_SIZE = ButtonSize.Large;

const BASE_CLASSES = 'flex items-center justify-center gap-2 px-6 w-auto border font-bold';

const variantClassNames: { [key in ButtonVariant]?: string } = {
  [ButtonVariant.Primary]: twMerge(
    BASE_CLASSES,
    'uppercase border-button-primary bg-button-primary text-button-text hover:bg-button-tint hover:text-button-tint-text'
  ),
  [ButtonVariant.PrimaryBorder]: twMerge(
    BASE_CLASSES,
    'uppercase border-button-primary fill-primary stroke-primary text-primary hover:bg-button-primary hover:text-button-text'
  ),
  [ButtonVariant.WhiteBorder]: twMerge(
    BASE_CLASSES,
    'border-grey-alternative bg-button-tint text-button-tint-text fill-button-tint-text stroke-button-tint-text hover:bg-button-primary hover:text-button-text hover:stroke-button-text hover:fill-button-text'
  ),
};

const sizeClassNames: { [key in ButtonSize]?: string } = {
  [ButtonSize.Small]: 'py-2 text-sm',
  [ButtonSize.Medium]: 'py-2 text-base',
  [ButtonSize.Large]: 'py-3.5 text-base',
};

const variantClassName = (variant: ButtonVariant = DEFAULT_VARIANT, noDefaultClasses?: boolean) => {
  const className = variantClassNames[variant];
  if (className) return className;
  if (noDefaultClasses) return null;
  return variantClassNames[DEFAULT_VARIANT];
};

const buttonSizeClasses = (buttonSize: ButtonSize = DEFAULT_SIZE) => {
  return sizeClassNames[buttonSize] || sizeClassNames[DEFAULT_SIZE];
};

export const buttonClasses = (
  variant?: ButtonVariant,
  size?: ButtonSize,
  noDefaultClasses?: boolean
) => {
  const calculatedVariantClassName = variantClassName(variant, noDefaultClasses);
  if (!calculatedVariantClassName) return null;
  return twMerge(calculatedVariantClassName, buttonSizeClasses(size));
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, size, noDefaultClasses, className, children, ...otherProps }: ButtonProps, ref) => {
    const buttonClassName = buttonClasses(variant, size, noDefaultClasses);
    if (!buttonClassName) return null;
    return (
      <button className={twMerge(buttonClassName, className)} ref={ref} {...otherProps}>
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
